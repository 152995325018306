<template>
  <div>
    <div class="contact custom" @click="isShowTg = true; showSelf = false" v-if="showSelf">
      <p>{{ $t('contact.customerService') }}</p> <!-- Translated text -->
    </div>
    <div class="custom" v-if="isShowTg" @click="isShowTg = false; showSelf = true;">
      <h5>{{ $t('contact.customerServiceNumber') }}</h5> <!-- Translated title -->
      <a :href="telegram_url" target="_blank" class="flex" v-if="telegram">
        <img src='../assets/images/icon_my_pub.png' />{{ telegram }}
      </a>
      <a :href="whatsapp_url" target="_blank" class="flex" v-if="whatsapp">
        <img src='../assets/images/icon_my_pub.png' />{{ whatsapp }}
      </a>
      <a :href="line_url" target="_blank" class="flex" v-if="line">
        <img src='../assets/images/icon_my_pub.png' />{{ line }}
      </a>
    </div>
  </div>
</template>
  
</template>

<script>
export default {
  name: 'Custom',
  data() {
    return {
      showSelf: true,  
      isShowTg: false,
      telegram: '',
      whatsapp: '',
      line: '',
      telegram_url: '',
      whatsapp_url: '',
      line_url: '',
    };
  },

  created() {
    this.axios.get(
        '/api/user/getCustom'
      ).then((res)=>{
        if(res.data.code == 1){
          this.telegram = res.data.data.telegram
          this.whatsapp = res.data.data.whatsapp
          this.line = res.data.data.line
          this.telegram_url = "https://t.me/"+ this.telegram
          this.whatsapp_url = "https://wa.me/+"+ this.whatsapp
          this.line_url = "https://line.me/"+ this.line
        }
      }).catch((res)=>{
        this.$checkError(res);
      })
  },

  mounted(){

  },

  methods: {
    
  }
}
</script>

<style scoped>
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明蒙版颜色 */
  z-index: 9999; /* 设置遮罩层的层级 */
}
</style>
