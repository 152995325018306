export default {
    message: {
        pleaseLogin: '请登录',
        hi: '你好，{username}',
        wallet: '我的钱包',
        balance: '余额',
        deposit: '充值',
        exchange: '出金',
        protectAccount: '请保护好自己的账户安全，充值前请和客服确认！',
        commonTools: '常用工具',
        myPublish: '我的发布',
        rechargeRecord: '充值记录',
        myCustomerService: '我的客服',
        fundDetails: '资金明细',
        logout: '退出登录',
        newMessage: '您有新的客服消息未回复',
        pleaseLogin: '请先登录',
        loadingText: '请求中',
        publishDemand: '发布需求',
        demandInfo: '需求信息',
        placeholderDemandInfo: '请输入到账类型（如：微信，支付宝，银行卡）、收款号码等',
        withdrawalAmount: '出金金额',
        placeholderWithdrawalAmount: '请输入出金金额',
        exchangeRateInfo: '汇率说明：',
        save: '保存',
        withdrawalAmountLabel: '出金金额', 
        exchangeRateLabel: '汇率',
        fillInInfo: '请填写发布信息',
        amountTooLow: '引出金額不能小于5000',
        placeholderSearch: '请输入订单号或银行',
        orderNumber: '订单号：',
        total: '合计',
        noData: '暂无数据~',
        detail: '详情',
        cancel: '取消',
        publicationTime: '发布时间',
        bankName: '银行名',
        demandInfo: '需求信息',
        customerName: '客户姓名',
        subbranchName: '支店名',
        transferType: '出金种类',
        accountNumber: '口座番号',
        total: '合计',
        viewReceipt: '查看回单',
        noReceipt: '暂无回单',
        exchangeRate: '可兑换约{money_jp}',
        submit: '提交',
        recharge: '充值',
        warmTips: '温馨提示',
        fundSecurity: '为了您的资金安全',
        confirmPayment: '付款前请和客服确认收款地址',
        walletAddress: '钱包地址：',
        contactCustomerService: '联系客服',
        copy: '一键复制',
        copySuccess: '文本已成功复制到剪切板！',
        copyFailure: '复制到剪切板失败，请手动复制。',
        record: '充值记录',
        amount: '充值（USDT）',
        recharge_usdt: '充值（USDT）',
        exchange_usdt: '出金（USDT）',
        hot_events: '热门活动',
        no_data: '暂无数据~',
        emptyMessage: '消息内容不能为空',
        fileSizeLimit: '附件大小不能超过20MB',
        confirmDelete: '此操作将永久删除该信息, 是否继续?',
        confirm: '确定',
        canceled: '已取消',
        wait: '待出金',
        success: '已出金',
        cancel_success: '取消成功',
        change_lang: '切换语言',
        money_less: '出金金额必须大于300U',
        minimum: '出金金额必须大于最低限制',
    },
    funds: {
        details: '资金明细',
        noData: '暂无数据~',
    },
    customerService: {
        title: '客服',
        noMessages: '暂无消息~',
        placeholder: '请输入您的消息...',
        send: '发送'
    },
    contact: {
        customerService: '联系客服',
        customerServiceNumber: '客服账号'
    },
    nav: {
        my: '我的',
        publish: '发布',
        recharge: '充值',
        activity: '公告'
    },
    login: {
        title: '登录',
        header: '登录',
        username_placeholder: '请输入用户名',
        password_placeholder: '请输入密码',
        code_placeholder: '请输入验证码',
        submit: '登录',
        register: '立即注册',
        forget_password: '忘记密码',
        empty_credentials: '账号密码不能为空',
        invalid_code: '验证码不正确',
        incomplete_info: '注册信息不完善',
    },
    register: {
        header: '注册',
        title: '注册',
        username_placeholder: '请输入用户名',
        password_placeholder: '请输入密码',
        confirm_password_placeholder: '请再次输入密码',
        mobile_placeholder: '请输入手机号',
        invite_code_placeholder: '请输入邀请码',
        submit: '注册',
        incomplete_info: '注册信息不完整',
    },
    recover_password: {
        title: '找回密码',
        heading: '找回密码',
        username_placeholder: '请输入用户名',
        mobile_placeholder: '请输入手机号',
        new_password_placeholder: '请输入新密码',
        confirm_new_password_placeholder: '请确认新密码',
        submit: '提交',
        username_mobile_password_empty: '账号、手机号码和密码不能为空。',
        password_mismatch: '两次新密码不一致。',
        password_recovery_success: '密码找回成功，请登录。',
    },
    type: {
        1: '人民币',
        2: '英镑',
        3: '卢布',
        4: '日币',
    },
};