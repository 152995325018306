<template>
  <div class="content login">
    <div class="header">
      <div class="left" @click="goback()"><img src="../assets/images/right_white.png" /></div>
      <p>{{ $t('recover_password.title') }}</p>
    </div>

    <div class="login_box flex">
      <img class="img_login" src="../assets/images/img_login.png" />
      <div class="login_form">
        <h1>{{ $t('recover_password.heading') }}</h1>
        <input class="input_text" type="text" v-model="username" :placeholder="$t('recover_password.username_placeholder')"/>
        <input class="input_text" type="text" v-model="mobile" :placeholder="$t('recover_password.mobile_placeholder')"/>
        <input class="input_text" type="password" v-model="newpass" :placeholder="$t('recover_password.new_password_placeholder')"/>
        <input class="input_text" type="password" v-model="newpass2" :placeholder="$t('recover_password.confirm_new_password_placeholder')"/>
        <input class="input_submit" type="submit" :value="$t('recover_password.submit')" @click="forgetPass()"/>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import { API_BASE_URL } from '../constant';
export default {
  name: 'ForgetView',
  components: {
    Footer
  },
  data() {
      return {
        username: "",
        mobile: "",
        newpass: "",
        newpass2: "",
      };
    },

  created() {

  },

  //模板渲染后调用
  mounted() {
    
  },

  // 方法
  methods: {
    //登录
    forgetPass(){
      if (!this.username || !this.mobile || !this.newpass) {
          this.$dialog(this.$t('recover_password.username_mobile_password_empty'));
          return false;
      }

      if (this.newpass !== this.newpass2) {
          this.$dialog(this.$t('recover_password.password_mismatch'));
          return false;
      }


      this.axios.post( '/api/user/resetpassword', {
        username: this.username,
        mobile: this.mobile,
        newpassword: this.newpass,
        newpassword2: this.newpass2,
      }).then((res)=>{
        var that = this
        
        if(res.data.code == 1){
          this.$dialog(this.$t('recover_password.password_recovery_success'));
          setTimeout(function(){
            that.$router.push({path:'/login'})
          }, 1000)
        }else{
          this.$dialog(res.data.msg);
          return false;
        }
      }).catch((res)=>{
        this.$checkError(res);
      })
    },

    goback(){
      this.$router.go(-1);
    },

  }
        
}
</script>

<style scoped>
</style>
