<template>
  <div class="content">
    <div class="header">
      <div class="left" @click="goback()">
        <img src="../assets/images/right_white.png" />
      </div>
      <p>{{ $t('message.recharge') }}</p>
      <div class="button btn_pub"></div>
    </div>

    <div class="notice flex">
      <div class="left">
        <h3>{{ $t('message.warmTips') }}</h3>
        <p>{{ $t('message.fundSecurity') }}</p>
        <p>{{ $t('message.confirmPayment') }}</p>
      </div>
      <img src="../assets/images/icon_custom.png" />
    </div>

    <div class="address">
      <a :href="qrcode">
        <img :src="qrcode" v-if="qrcode" />
      </a>
      <div class="addr">
        <p>{{ $t('message.walletAddress') }}</p>
        <p :title="payee">{{ payee }}</p>
        <button @click="copyToClipboard()">{{ $t('message.copy') }}</button>
      </div>
    </div>
    <button class="btn_contact" @click="contact()">{{ $t('message.contactCustomerService') }}</button>

    <Custom></Custom>
  </div>
</template>

<script>
import Custom from '@/components/Custom.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'OrderView',
  components: {
    Footer, Custom
  },
  data() {
      return {
        qrcode: '',
        payee: '',
      };
    },

  created() {
    if(!this.checkLogin()) return false;
    this.axios.get(
          '/api/user/rechargeinfo', {
            headers: {
            'token' : localStorage.getItem('token')
            },
          },
        ).then((res)=>{
          if(res.data.code != 1){
            this.$dialog(res.data.msg);
            return;
          }else{
            this.qrcode = res.data.data.qrcode;
            this.payee = res.data.data.payee;
          }
        }).catch((res)=>{
          this.$checkError(res);
        })
  },

  //模板渲染后调用
  mounted() {
  },

  // 方法
  methods: {
    contact(){
      this.$router.push({path:'/chat'})
    },

    goback(){
      this.$router.push({path:'/recharge'})
    },
    checkLogin(){
      const token = localStorage.getItem("token");
      if(!token){
        this.$dialog(this.$t('message.pleaseLogin'));
        setTimeout(() => {
          this.$router.push({path:'/login'})
        }, 1000);
        return false;
      }
      return true;
    },
    copyToClipboard() {
        const textToCopy = this.payee;

        if (!navigator.clipboard) {
            this.fallbackCopyTextToClipboard(textToCopy);
            return;
        }

        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                this.$dialog(this.$t('message.copySuccess')); // Translated success message
            })
            .catch(err => {
                console.error('复制到剪切板失败:', err);
                this.$dialog(this.$t('message.copyFailure')); // Translated failure message
            });
    },

    fallbackCopyTextToClipboard(text) {
        const textArea = document.createElement("textarea");
        textArea.value = text;

        // Avoid scroll on mobile devices
        textArea.style.position = "fixed";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            const successful = document.execCommand('copy');
            const msg = successful 
                ? this.$t('message.copySuccess') 
                : this.$t('message.copyFailure'); // Translated message
            this.$dialog(msg);
        } catch (err) {
            console.error('复制到剪切板失败:', err);
            this.$dialog(this.$t('message.copyFailure')); // Translated failure message
        }

        document.body.removeChild(textArea);
    }
  },
        
}
</script>

<style scoped>
  .content{
    height: auto !important;
    padding: 15px;
  }
  .notice {
    padding-top: 40px;
  }
  .notice .left{
    text-align: left;
  }
  .notice .left h3{
    font-size: 16px;
    padding-bottom: 5px;
  }
  .notice img{
    height: 50px;
  }
  .address{
    width: 100%;
    padding: 30px;
    background: #1E2B5F;
    border-radius: 15px;
    margin-top: 25px;
  }
  .address img{
    width: calc(100% - 50px);
    margin: 0 auto;
  }
  .address .addr{
    padding-top: 30px;
  }
  .address .addr p{
    line-height: 25px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .address .addr button{
    background: #27DB9E;
    border: none;
    padding: 5px 10px;
    border-radius: 25px;
    margin-top: 10px;
    font-size: 13px;
  }
  .btn_contact{
    width: 100%;
    height: 45px;
    background: #1E2B5F;
    color: #fff;
    border: none;
    margin-top: 50px;
    font-size: 16px;
    border-radius: 10px;
  }
  @media screen and (min-width: 640px) {
    .notice{
      padding-top: 60px;
    }
    .address{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .address img{
      width: 40%;
      margin: 0;
    }
    .address .addr{
      width: 100%;
    }
    .address .addr p{
      text-align: center;
      line-height: 40px;
      font-size: 16px;
    }
    .address .addr button{
      padding: 10px 20px;
    }
  }
</style>
