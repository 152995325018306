export default {
    message: {
        pleaseLogin: 'Please log in',
        hi: 'Hi, {username}',
        wallet: 'My Wallet',
        balance: 'Balance',
        deposit: 'Deposit',
        exchange: 'Exchange',
        protectAccount: 'Please protect your account, confirm with customer service before recharging!',
        commonTools: 'Common Tools',
        myPublish: 'My Publish',
        rechargeRecord: 'Recharge Record',
        myCustomerService: 'Customer Service',
        fundDetails: 'Fund Details',
        logout: 'Logout',
        newMessage: 'You have new customer service messages unresponded',
        pleaseLogin: 'Please log in',
        loadingText: 'Loading...',
        publishDemand: 'Publish',
        demandInfo: 'Demand Information',
        placeholderDemandInfo: 'Please enter demand information',
        withdrawalAmount: 'Amount',
        placeholderWithdrawalAmount: 'Please enter withdrawal amount',
        exchangeRateInfo: 'Exchange Rate:',
        save: 'Save',
        withdrawalAmountLabel: 'Amount',
        exchangeRateLabel: 'Exchange Rate',
        fillInInfo: 'Please fill in the publish information',
        amountTooLow: 'Withdrawal amount cannot be less than 5000',
        placeholderSearch: 'Please enter order number or bank',
        orderNumber: 'Order No:',
        total: 'Total',
        noData: 'No data available~',
        detail: 'Detail',
        cancel: 'Cancel',
        publicationTime: 'Time',
        bankName: 'Bank Name',
        demandInfo: 'Demand Info',
        customerName: 'Customer Name',
        subbranchName: 'Subbranch Name',
        transferType: 'Transfer Type',
        accountNumber: 'Account Number',
        total: 'Total',
        viewReceipt: 'View Receipt',
        noReceipt: 'No Receipt Available',
        exchangeRate: 'Approximately {money_jp} can be exchanged',
        submit: 'Submit',
        recharge: 'Recharge',
        warmTips: 'Warm Tips',
        fundSecurity: 'For your fund security',
        confirmPayment: 'Please confirm the payment address with customer service',
        walletAddress: 'Wallet Address:',
        contactCustomerService: 'Contact Customer Service',
        copy: 'Copy to Clipboard',
        copySuccess: 'Text has been successfully copied to the clipboard!',
        copyFailure: 'Failed to copy to clipboard, please copy manually.',
        record: 'Recharge Record',
        amount: 'Recharge (USDT)',
        recharge_usdt: 'Deposit（USDT）',
        exchange_usdt: 'Withdraw（USDT）',
        hot_events: 'Notice',
        no_data: 'No data available~',
        emptyMessage: 'Message content cannot be empty',
        fileSizeLimit: 'Attachment size cannot exceed 20MB',
        confirmDelete: 'This action will permanently delete the information, do you want to continue?',
        confirm: 'Confirm',
        canceled: 'Cancelled',
        wait: 'Waiting',
        success: 'Success',
        cancel_success: 'Cancel success',
        change_lang: 'Language',
        money_less: 'Amount must more than $300',
        minimum: 'Amount must more than minimum',
    },
    funds: {
        details: 'Funds Details',
        noData: 'No data available~',
    },
    customerService: {
        title: 'Customer Service',
        noMessages: 'No messages available~',
        placeholder: 'Type your message here...',
        send: 'Send'
    },
    contact: {
        customerService: 'Customer Service',
        customerServiceNumber: 'Customer Service'
    },
    nav: {
        my: 'My',
        publish: 'Publish',
        recharge: 'Recharge',
        activity: 'Notice'
    },
    login: {
        title: 'Login',
        header: 'Login',
        username_placeholder: 'Please enter username',
        password_placeholder: 'Please enter password',
        code_placeholder: 'Verification code',
        submit: 'Login',
        register: 'Register',
        forget_password: 'Forgot',
        empty_credentials: 'Username and password cannot be empty',
        invalid_code: 'Invalid verification code'
    },
    register: {
        header: 'Register',
        title: 'Register',
        username_placeholder: 'Please enter username',
        password_placeholder: 'Please enter password',
        confirm_password_placeholder: 'Please confirm your password',
        mobile_placeholder: 'Please enter your phone number',
        invite_code_placeholder: 'Please enter invite code (optional)',
        submit: 'Register',
        incomplete_info: 'Registration information is incomplete',
    },
    recover_password: {
        title: 'Retrieve Password',
        heading: 'Retrieve Password',
        username_placeholder: 'Please enter your username',
        mobile_placeholder: 'Please enter your telegram id',
        new_password_placeholder: 'Please enter your new password',
        confirm_new_password_placeholder: 'Please confirm your new password',
        submit: 'Submit',
        username_mobile_password_empty: 'Username, telegram id, and password cannot be empty.',
        password_mismatch: 'The two new passwords do not match.',
        password_recovery_success: 'Password recovery successful, please log in.',
    },
    type: {
        1: 'CNY',
        2: 'GBP',
        3: 'RUB',
        4: 'JPY',        
    },
};