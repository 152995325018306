import Vue from 'vue'
import VueI18n from 'vue-i18n'

// 引入自定义中文包
import customZH from './locales/zh'

// 引入自定义英文包
 import customEN from './locales/en'


Vue.use(VueI18n)

// 准备翻译的语言环境信息
const messages = {
  en: {
    message: {
      hello: 'hello world'
    },
    ...customEN // 将自定义英文包加入
  },
  zh: {
    message: {
      hello: '您好世界！'
    },
    ...customZH // 将自定义英文包加入
  },  
}

// 通过选项创建 VueI18n 实例
const lang = localStorage.getItem('language') || 'en';
const i18n = new VueI18n({
  locale: lang, // 设置地区
  messages // 设置地区信息
})

export default i18n