<template>
  <div class="list">
    <div class="item">
      <div class="order flex">
        <p>{{ order_sn }}</p>
        <span v-if="status == 0">{{ $t('message.wait') }}</span>
        <span class="complete" v-if="status == 1">{{ $t('message.success') }}</span>
        <span v-if="status == 2">{{ $t('message.cancel') }}</span>
      </div>
      <div class="my_item flex info">
        <p v-if="payee">{{ $t('message.bankName') }}</p>
        <p v-else>{{ $t('message.demandInfo') }}</p>
        <div v-html="bank_name"></div>
      </div>
      <div class="my_item flex" v-if="payee">
        <p>{{ $t('message.customerName') }}</p>
        <input :value="payee" readonly/>
      </div> 
      <div class="my_item flex" v-if="subbranch">
        <p>{{ $t('message.subbranchName') }}</p>
        <input :value="subbranch" readonly/>
      </div> 
      <div class="my_item flex" v-if="transfer_type">
        <p>{{ $t('message.transferType') }}</p>
        <input :value="transfer_type" readonly/>
      </div> 
      <div class="my_item flex" v-if="cs_no">
        <p>{{ $t('message.accountNumber') }}</p>
        <input :value="cs_no" readonly/>
      </div> 
      <div class="my_item flex">
        <p>{{ $t('message.withdrawalAmount') }}</p>
        <input :value="transfer_amount" readonly/>
      </div> 
      <div class="my_item flex">
        <p>{{ $t('message.publicationTime') }}</p>
        <input :value="createtime" readonly/>
      </div> 
      <div class="operates flex">
        <p>{{ $t('message.total') }}：<b>{{ amount }}<span> (USDT)</span></b></p>
        <button @click="showImg()">{{ $t('message.viewReceipt') }}</button>
      </div>

      <div class="order_img" v-if="is_show">
        <img :src="receipt" @click="preview=true"/>
      </div>       
    </div>

    <div class="preview" @click="preview=false" v-if="preview">
      <img :src="receipt">
    </div>
  </div>
</template>

<script>
import { API_BASE_URL } from '../constant';
export default {
name: 'DetailView',
props: {
  bank_name: '',
  subbranch: '',
  transfer_type: '',
  createtime: '',
  payee: '',
  cs_no: '',
  extra: '',
  amount: 0.00,
  transfer_amount: 0.00,
  name_change: '',
  status_text: '',
  status: 0,
  order_sn: '',
  receipt: '',
  preview: false
},
data() {
  return {
    is_show: false,
  };
},

created() {
  if(this.receipt) this.receipt = this.receipt;
},

methods: {
  showImg() {
    if(!this.receipt) {
      this.$dialog(this.$t('message.noReceipt'));
      return false;
    }
    this.is_show = !this.is_show;
  },

  publish() {
    this.$router.push({ path: '/publish' });
  },

  goback() {
    this.$router.push({ path: '/publist' });
  }
},
}
</script>

<style scoped>
.content{
  padding: 50px 0 0 0;
}
.order_img{
  width: 100%;
  margin-top: 20px;
}
.order_img img{
  width: 100%;
}
.header .btn_pub{
  color: #fff;
  border-radius: 5px;
  background: #27db9e;
  padding: 0 10px;
}
.list{
  padding: 15px;
}
.list .item{
  background: #1E2B5F;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 15px;
}
.list .item .order {
  padding-bottom: 10px;
  border-bottom: 1px solid #979797;
  font-size: 16px;
  margin-bottom: 5px;
}
.list .item .order span{
  display: block;
  padding: 5px 10px;
  font-size: 12px;
  color: #fff;
  background: #E577AE;
  border-radius: 3px;
}
.list .item .order span.complete{
  background: #27db9e;
}
.list .item .my_item {
  line-height: 30px;
}
.list .item .my_item.info {
  align-items: flex-start;
}
.list .item .my_item p{
  width: 30%;
  color: #C3C1C1;
  text-align: left;
 }
 .list .item .my_item input, .list .item .my_item div{
  width: 70%;
  border: none;
  background: none;
  color: #fff;
  padding-left: 10px;
 }
 .list .item .my_item div{
  text-align: left
 }
.list .item .operates{
  padding-top: 10px;
}
.list .item .operates p{
  font-size: 16px;
}
.list .item .operates p b{
  font-size: 18px;
  color: #27db9e;
}
.list .item .operates p b span{
  color: #27db9e;
}
.list .item .operates button{
  border: 1px solid #fff;
  padding: 5px 10px;
  background: none;
  color: #fff;
  border-radius: 30px;
}
.preview{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 60px 10px 10px 10px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: #fff;
}
.preview img{
  width: 100%;
}
@media screen and (min-width: 640px) {
  .order_img[data-v-42d98c49] {
    margin-top: 50px;
    text-align: left;
  }
  .order_img img{
    max-width: 350px;
  }
}
</style>
