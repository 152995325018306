import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUi from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import './assets/css/common.css'
import "./assets/js/config"
import i18n from './lang'

//api地址
// axios.defaults.baseURL = 'http://54.206.191.92';
axios.defaults.baseURL = '/';

Vue.config.productionTip = false
Vue.prototype.axios = axios;

// 创建全局方法
Vue.prototype.$dialog = function (msg) {
  this.$message.closeAll(); 
  this.$message({ message: msg, customClass: 'toast', duration: 2000 });
};

Vue.prototype.$checkError = function (res){
  if(res.response.data.code == 401){
    localStorage.removeItem('token');
    this.$dialog('登录已过期');
    setTimeout(() => {
      this.$router.push({path:'/login'})
    }, 2000);
  }else{
    console.error(res);
  }
}

Vue.use(ElementUi)
new Vue({
  router,
  store,
  i18n,
  render: function (h) { return h(App) }
}).$mount('#app')

