<template>
  <div class="content login">
    <div class="header">
      <div class="left" @click="goback()"><img src="../assets/images/right_white.png" /></div>
      <p>{{ $t('register.header') }}</p>
    </div>

    <div class="login_box flex">
      <img class="img_login" src="../assets/images/img_login.png" />
      <div class="login_form">
        <h1>{{ $t('register.title') }}</h1>
        <input class="input_text" type="text" v-model="username" :placeholder="$t('register.username_placeholder')"/>
        <input class="input_text" type="password" v-model="password" :placeholder="$t('register.password_placeholder')"/>
        <input class="input_text" type="password" v-model="password2" :placeholder="$t('register.confirm_password_placeholder')"/>
        <input class="input_text" type="text" v-model="mobile" :placeholder="$t('register.mobile_placeholder')"/>
        <input class="input_text" type="text" v-model="code" :placeholder="$t('register.invite_code_placeholder')"/>
        <input class="input_submit" type="submit" :value="$t('register.submit')" @click="register()"/>
      </div>
    </div>
  </div>
</template>


<script>
import Footer from '@/components/Footer.vue'
export default {
  name: 'RegisterView',
  components: {
    Footer
  },
  data() {
      return {
        username: "",
        password: "",
        password2: "",
        mobile: "",
        code: '',
        agree: false,
      };
    },

  created() {

  },

  //模板渲染后调用
  mounted() {
  },

  // 方法
  methods: {
    //注册
    register(){
      if (!this.username || !this.mobile || !this.password || !this.password2 || !this.code) {
        this.$dialog(this.$t('register.incomplete_info'));
        return;
      }

      this.axios.post(
        '/api/user/register', {
          username: this.username,
          mobile: this.mobile,
          password: this.password,
          password2: this.password2,
          invite_code: this.code,
        }
      ).then((res)=>{
        var that = this
        this.$dialog(res.data.msg);
        if(res.data.code == 1){
          localStorage.setItem('token', res.data.data.userinfo.token)
          setTimeout(function(){
            that.$router.push({path:'/index'})
          }, 1000)
        }else{
          return
        }
      }).catch((res)=>{
        this.$checkError(res);
      })
    },

    goAuth(){
      this.$router.push({path:'/auth'})
    },

    // 返回上一页
    goback(){
      this.$router.go(-1);
    },
  }
        
}
</script>

<style scoped>
</style>
