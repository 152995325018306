<template>
  <div class="footer flex">
      <div v-for="(item, index) in translatedNavItems" class="navbar" :class="index == activeIndex ? 'active' : ''">
        <router-link :to="item.url">
          <img :src="index == activeIndex ? item.selected : item.icon" />
          <p>{{item.title}}</p>
        </router-link>
      </div>
      <!-- <div class="navbar"><img src="../assets/img/icon_kefu.png" /><p>客服</p></div>
      <div class="navbar"><img src="../assets/img/icon_notice.png" /><p>公告</p></div>
      <div class="navbar"><img src="../assets/img/icon_member.png" /><p>我的</p></div> -->
    </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    activeIndex: 0,
  },
  data() {
    return {
      navItemArr: [
        {
          titleKey: 'nav.my',
          icon: require('@/assets/images/nav_home.png'),
          selected: require('@/assets/images/nav_home_on.png'),
          url: '/index'
        },
        {
          titleKey: 'nav.publish',
          icon: require('@/assets/images/nav_publish.png'),
          selected: require('@/assets/images/nav_publish_on.png'),
          url: '/publist'
        },
        {
          titleKey: 'nav.recharge',
          icon: require('@/assets/images/nav_recharge.png'),
          selected: require('@/assets/images/nav_recharge_on.png'),
          url: '/recharge'
        },
        {
          titleKey: 'nav.activity',
          icon: require('@/assets/images/nav_chat.png'),
          selected: require('@/assets/images/nav_chat_on.png'),
          url: '/activity'
        },
      ],
    };
  },
  computed: {
    translatedNavItems() {
      return this.navItemArr.map(item => ({
        ...item,
        title: this.$t(item.titleKey) // 使用翻译键动态获取翻译
      }));
    }
  },
  mounted(){
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media screen and (max-width: 640px) {
  .footer{
    width: 100%;
    padding: 10px 20px;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #0a113d;
  }
  .footer .navbar img{
      height: 25px;
  }
  .footer .active p{
      color: #27DB9E;
  }
}
@media screen and (min-width: 640px) {
  .footer{
    width: 100%;
    max-width: 1080px;
    padding: 10px 20px;
    position: fixed;
    bottom: 0;
    left: 50%;
    background: #1E2B5F;
    transform: translate(-50%, 0);
    border-top: 1px solid #666;
  }
  .footer .navbar{
    width: 25%;
    text-align: center;
  }
  .footer .navbar img{
      height: 28px;
  }
  .footer .active p{
      color: #27DB9E;
  }
}
</style>
