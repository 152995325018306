<template>
  <div class="content">
    <div class="header">
      <div class="left" @click="goback()">
        <img src="../assets/images/right_white.png" />
      </div>
      <p>{{ $t('message.record') }}</p> <!-- Translated title -->
      <div class="button btn_pub"></div>
    </div>
    
    <div class="list">
      <div class="item flex" v-for="(item, index) in record" :key="index">
        <div class="left">
          <p>{{ $t('message.amount') }}</p> <!-- Translated amount label -->
          <span>{{ item.createtime }}</span>
        </div>
        <p>{{ item.money }}</p>
      </div>
    </div>

    <div class="no_date" v-if="record.length == 0">{{ $t('message.noData') }}</div> <!-- Translated no data message -->

    <Footer activeIndex="0"></Footer>
  </div>
</template>


<script>
import Footer from '@/components/Footer.vue'
import { API_BASE_URL } from '../constant';
export default {
  name: 'PublistView',
  components: {
    Footer
  },
  data() {
      return {
        record: [],
      };
    },

  created() {
    const token = localStorage.getItem('token')
    if(!token){
      return;
    }
    this.axios.get(
        '/api/user/rechargelog', {
          headers: {
            'token' : token
          },
        }
      ).then((res)=>{
        if(res.data.code == 1){
          this.record = res.data.data;
        }else{
          this.$dialog(res.data.msg);
        }
      }).catch((res)=>{
        this.$checkError(res);
      })
  },

  //模板渲染后调用
  mounted() {
    this.checkLogin();
  },

  // 方法
  methods: {
    goback(){
    this.$router.push({path: '/index'})
    },
    checkLogin(){
      const token = localStorage.getItem("token");
      if(!token){
        this.$dialog(this.$t('message.pleaseLogin'));
        return false;
      }
      return true;
    }
  },
        
}
</script>

<style scoped>
.content{
  padding: 50px 15px 80px;
  overflow-y: scroll;
}
.list{
  background: #1E2B5F;
  border-radius: 10px;
  padding: 0 15px;
}
.list .item{
  padding: 15px 0;
  border-bottom: 1px solid #979797;
}
.list .item:last-child{
  border: none;
}
.list .item .left{
  text-align: left;
}
.list .item .left p{
  font-size: 16px;
  padding-bottom: 5px;
}
.list .item .left span{
  font-size: 13px;
  color: #B9B9B9;
}
.list .item > p{
  font-size: 20px;
  color: #27DB9E;
}
@media screen and (min-width: 640px) {
  .list{
    margin: 20px 0;
  }
}
</style>
