<template>
  <div class="content">
    <div class="wallet">
      <div class="money">
        <b>{{ comBalance }}</b><p>{{ $t('message.balance') }}</p>
      </div>
    </div>

    <div class="recharge">
      <div class="tit">{{ $t('message.recharge') }}</div>
      <ul>
        <li :class="money == 300 ? 'selected' : ''" @click="selectMoney(300)">300</li>
        <li :class="money == 2000 ? 'selected' : ''" @click="selectMoney(2000)">2000</li>
        <li :class="money == 5000 ? 'selected' : ''" @click="selectMoney(5000)">5000</li>
        <li :class="money == 10000 ? 'selected' : ''" @click="selectMoney(10000)">10000</li>
        <li :class="money == 50000 ? 'selected' : ''" @click="selectMoney(50000)">50000</li>
        <li :class="money == 100000 ? 'selected' : ''" @click="selectMoney(100000)">100000</li>
      </ul>
      <input type="text" v-model="money" placeholder="请输入自定义金额" />
      <div>RMB:{{ RMBmoney }}</div>

      <button @click="submit()">{{ $t('message.submit') }}</button>
      <span style="display: none;">
        <audio id="audio" src="../assets/images/hongbao.mp3" controls="controls"></audio>
      </span>
    </div>

    <Footer activeIndex="2"></Footer>
  </div>
</template>



<script>
import Footer from '@/components/Footer.vue'
export default {
  name: 'OrderView',
  components: {
    Footer
  },
  data() {
      return {
        money: '',
        balance: '-',
        audio: '',
        money_jp: 0,
        rates:[],
      };
    },
  watch:{
    money(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.calcjpy();
      }
    },
  },
  created() {
    const token = localStorage.getItem('token')
    if(!token){
      return;
    }
    this.axios.get(
        '/api/user/info', {
          headers:{
            'token': token,
          }
        }
      ).then((res)=>{
        if(res.data.code == 1){
          this.balance = res.data.data.userinfo.money
        }
      }).catch((res)=>{
        this.$checkError(res);
      })
  },

  //模板渲染后调用
  mounted() {
    this.checkLogin()
    this.audio = document.getElementById("audio");
    this.getRates();
  },

  computed:{
    comBalance: function(){
      return this.getRmb(this.balance);
    },
    RMBmoney: function(){
      return this.getRmb(this.money);
    }
  },

  // 方法
  methods: {
    getRates(){
        this.axios.get(
            '/api/user/conversionRates?status=1', 
            {
              headers: {
                'token' : localStorage.getItem('token')
              },
            }
          ).then((res)=>{        
            if(res.data.code == 1){
              this.rates = res.data.data.rateList;
            }  
          }).catch((res)=>{
            console.error('Error fetching data:', res);
          })
    },
    calcjpy(){

        this.axios.get('/api/user/conversion',{
            params:{
              'amount': this.money
            },
            headers: {
              'token' : localStorage.getItem('token')
            },
          }
        ).then((res)=>{
          console.log(res);
          this.money_jp = res.data.data.jpy; // 根据实际返回数据格式调整
        }).catch((res)=>{
          console.error('Error fetching data:', res);
        });
    },
    //选择金额
    selectMoney(number){
      this.money = number
    },

    //提交充值，并跳转到钱包地址
    submit(){
      if(this.money < 300){
        this.$dialog(this.$t('message.money_less'));
        return;
      }
      this.axios.post(
          '/api/user/recharge', {
            'amount': this.money
          },
          {
            headers: {
            'token' : localStorage.getItem('token')
            },
          }
        ).then((res)=>{
          if(res.data.code != 1){
            this.$dialog(res.data.msg);
            return;
          }
          this.playSong(1)
          setTimeout(() => {
            this.$router.push({path: '/wallet'})
          }, 1000);
        }).catch((res)=>{
          this.$checkError(res);
        })
    },

    checkLogin(){
      const token = localStorage.getItem("token");
      if(!token){
        this.$dialog(this.$t('message.pleaseLogin'));
        setTimeout(() => {
          this.$router.push({path:'/login'})
        }, 1000);
        return false;
      }
      return true;
    },
    playSong(wi) {
      this.audio.load();
      this.stopSong();
      this.audio.play();
      if (wi == 0) {
        this.audio.pause();
      }
    },
    stopSong() {
      this.audio.pause();
    },

    getRmb(num){
      let rmbNum=num;
      num = Number(num);
      this.rates.forEach(element => {
        if (num >= element.from  && num <= element.to ) {
          rmbNum = num*element.rate;
        }
      });
      return rmbNum;
    }
  },
        
}
</script>

<style scoped>
  .content{
    padding: 15px 15px 70px;
    overflow-y: scroll;
  }
  .wallet{
    width: 100%;
    background: url(../assets/images/bg_member.png) no-repeat center;
    border-radius: 10px;
    overflow: hidden;
    padding: 20px;
  }
  .wallet .money b{
    font-size: 28px;
  }
  .wallet .list{
    padding-top: 20px;
  }
  .wallet .list .item{
    width: 50%;
    text-align: center;
  }
  .wallet .list .item b{
    font-size: 20px;
    display: block;
    padding-top: 5px;
  }
  .recharge .tit{
    text-align: left;
    border-left: 4px solid #27DB9E;
    height: 16px;
    font-size: 16px;
    padding-left: 10px;
    line-height: 16px;
    margin-top: 20px;
  }
  .recharge ul{
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .recharge ul li{
    flex: 0 0 calc(50% - 10px);
    height: 70px;
    background: url(../assets/images/bg_price.png) no-repeat center;
    background-size: 100% 100%;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 15px;
    font-size: 20px;
    line-height: 70px;
  }
  .recharge ul li.selected{
    background: #27DB9E;
  }
  .recharge input{
    width: 100%;
    height: 50px;
    color: #fff;
    font-size: 16px;
    border-radius: 10px;
    background: #1E2B5F;
    border: none;
    margin-top: 15px;
    padding-left: 10px;
  }
  .recharge button{
    width: 100%;
    height: 45px;
    border-radius: 10px;
    background: #298DE0;
    color: #fff;
    font-size: 18px;
    margin: 20px 0;
    border: none;
  }
  @media screen and (min-width: 640px) {
    .wallet{
      width: 100%;
      background: url(../assets/images/bg_member.png) no-repeat center;
      background-size: 120% 120%;
      border-radius: 10px;
      overflow: hidden;
      padding: 40px;
    }
    .recharge ul li{
        flex: 0 0 calc(50% - 10px);
        height: 90px;
        background: url(../assets/images/bg_price.png) no-repeat center;
        background-size: 120% 120%;
        border-radius: 10px;
        overflow: hidden;
        margin-top: 25px;
        font-size: 20px;
        line-height: 90px;
        cursor: pointer;
    }
    .recharge input{
      height: 90px;
      padding-left: 20px;
      margin-top: 25px;
    }
    .recharge button{
      height: 55px;
      margin: 20px 0;
    }
  }
</style>
