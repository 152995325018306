<template>
  <div class="content">
    <div class="headimg flex">
      <div class="img flex">
        <img src="../assets/images/avatar.png" />
        <p v-if="!username" @click="login">{{ $t("message.pleaseLogin") }}</p>
        <p v-if="username">{{ $t("message.hi", { username }) }}</p>
      </div>
      <!-- <div class="button btn_recharge" @click="recharge()">
        <img src="../assets/images/nav_recharge.png" /> <span>{{ $t("message.deposit") }}</span>
      </div> -->
      <el-dropdown trigger="click" @command="changeLanguage">
        <div style="background: #1E2B5F;max-width: 200px;border-radius: 20px;padding: 0 10px;cursor: pointer;">
          <span style="height: 30px;line-height: 30px;">{{ $t("message.change_lang")}}</span>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="zh" :disabled="'zh'=== $i18n.locale ">简体中文</el-dropdown-item>
          <el-dropdown-item command="en" :disabled="'en'=== $i18n.locale ">English</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <div class="tips">
      <el-alert
        type="warning"
        effect="dark"
        :title="$t('message.newMessage')"
        v-if="msgshow">
      </el-alert>
    </div>
    
    <div class="wallet">
      <div class="bg"><img src="../assets/images/bg_member.png" /></div>
      <div class="wallet_info">
        <h3>{{ $t("message.wallet") }} <span>(RMB)</span></h3>
        <ul class="flex">
          <li><p>{{ $t("message.balance") }}</p><b>{{ comBalance }}</b></li>
          <li><p>{{ $t("message.deposit") }}</p><b>{{ comDeposit }}</b></li>
          <li><p>{{ $t("message.exchange") }}</p><b>{{ comExchange }}</b></li>
        </ul>
        <div class="warning flex" v-if="username">
          <img src="../assets/images/icon_voice.png" />
          <p>{{ $t("message.protectAccount") }}</p>
        </div>
      </div>
    </div>    

    <div class="member_nav">
      <h2>{{ $t("message.commonTools") }}</h2>
      <ul>
        <li class="flex" @click="publist()">
          <div class="left flex">
            <img src="../assets/images/icon_my_pub.png" />
            <p>{{ $t("message.myPublish") }}</p>            
          </div>
          <img src="../assets/images/right_white.png" />
        </li>
        <li class="flex" @click="record()">
          <div class="left flex">
            <img src="../assets/images/icon_recharge.png" />
            <p>{{ $t("message.rechargeRecord") }}</p>            
          </div>
          <img src="../assets/images/right_white.png" />
        </li>
        <li class="flex" @click="chat()">
          <div class="left flex">
            <img src="../assets/images/icon_kefu.png" />
            <p>{{ $t("message.myCustomerService") }}</p>  
          </div>
          <img src="../assets/images/right_white.png" />
        </li>
        <li class="flex" @click="record2()">
          <div class="left flex">
            <img src="../assets/images/icon_wallet.png" />
            <p>{{ $t("message.fundDetails") }}</p>            
          </div>
          <img src="../assets/images/right_white.png" />
        </li>        
      </ul>
    </div>

    <button @click="logout()" v-if="username" class="logout">{{ $t("message.logout") }}</button>            

    <Custom></Custom>
    <Footer activeIndex="0"></Footer>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import Custom from '@/components/Custom.vue'
import { API_BASE_URL } from '../constant';
export default {
  name: 'IndexView',
  components: {
    Footer, Custom
  },
  data() {
      return {
       balance: 0.00,
       exchange: 0.00,
       deposit: 0.00,
       inviteCode : '-',
       kefu: '-',
       username: '',
       msgshow: 0,
       rates:[]
      };
    },

  created() {
    const token = localStorage.getItem('token')
    if(!token){
      return;
    }
    this.axios.get(
        '/api/user/info', {
          headers:{
            'token': token,
          }
        }
      ).then((res)=>{
        if(res.data.code == 1){
          this.username = res.data.data.userinfo.username
          this.balance = res.data.data.userinfo.money
          this.inviteCode = res.data.data.userinfo.invite_code
          this.exchange = res.data.data.userinfo.exchange
          this.deposit = res.data.data.userinfo.deposit
          this.msgshow = !res.data.data.userinfo.reply_status
          this.kefu = res.data.data.userinfo.kefu
        }
      }).catch((res)=>{
        this.$checkError(res);
      })
  },

  //模板渲染后调用
  mounted() {
    this.getRates();
  },
  computed:{
    comBalance: function(){
      return this.getRmb(this.balance);
    },
    comExchange: function(){
      return this.getRmb(this.exchange);
    },
    comDeposit: function(){
      return this.getRmb(this.deposit);
    },
  },

  // 方法
  methods: {
    login(){
      this.$router.push({path: "/login"});
    },

    logout(){
      const token = localStorage.getItem("token");
      if(!token) return false;
      this.axios.post('/api/user/logout',{}, {
          headers: {
            'token' : token
          },
        }).then(res => {
          if(res.data.code == 1){
            localStorage.removeItem('token')
            this.username = '';
            this.balance = 0;
            this.exchange = 0;
            this.deposit = 0;
            this.inviteCode = '-';
          }
          this.$dialog(res.data.msg);
        }).catch(error => {
          console.error('Error fetching data:', error);
        });
    },
    changeLanguage(lang) {
      console.log(lang);
      localStorage.setItem("language", lang)
      this.$i18n.locale = lang // 设置给本地的i18n插件
    },

    recharge(){
      this.$router.push({path: "/recharge"});
    },

    publist(){
      this.$router.push({path: "/publist"});
    },

    chat(){
      this.$router.push({path: "/chat"});
    },

    record(){
      this.$router.push({path: "/record"});
    },

    record2(){
      this.$router.push({path: "/moneyrecord"});
    },

    //校验登录
    checkLogin(){
      const token = localStorage.getItem("token");
      if(!token){
        this.$dialog(this.$t('message.pleaseLogin'));
        return false;
      }
      return true;
    },

    getRates(){
        this.axios.get(
            '/api/user/conversionRates?status=1', 
            {
              headers: {
                'token' : localStorage.getItem('token')
              },
            }
          ).then((res)=>{        
            if(res.data.code == 1){
              this.rates = res.data.data.rateList;
            }  
          }).catch((res)=>{
            console.error('Error fetching data:', res);
          })
    },

    getRmb(num){
      let rmbNum=num*7;
      num = Number(num);
      this.rates.forEach(element => {
        if (num >= element.from  && num <= element.to ) {
          rmbNum = num*element.rate;
        }
      });
      return rmbNum;
    }
  },
        
}
</script>

<style scoped>
@media screen and (max-width: 640px) {
  .logout{
    background: #1e2b5f;
    width: calc(100% - 30px);
    height: 45px;
    margin: 0 auto 40px;
    font-size: 16px;
  }
  .btn_login{
    background: #27DB9E;
  }
  .member_nav{
    width: calc(100% - 30px);
    margin: 30px auto;
    background: #1E2B5F;
    border-radius: 10px;
    padding: 20px 10px 0px 10px;
  }
  .member_nav h2{
    text-align: left;
    font-size: 18px;
  }
  .member_nav ul{
    padding-top: 10px;
  }
  .member_nav ul li{
    padding: 15px 0;
    border-bottom: 1px solid #eee;
  }
  .member_nav ul li:last-child{
    border: none;
  }
  .member_nav ul li .left img{
    height: 35px;
  }
  .member_nav ul li .left p{
    padding-left: 10px;
  }
  .member_nav ul li > img{
    height: 15px;
  }
  .content{
    padding: 0 0 60px;
    height: auto;
  }
  .headimg{
    padding: 15px;
  }
  .headimg .img img{
    width: 40px;
    height: 40px;
    border-radius: 80px;
    overflow: hidden;
    border: 3px solid #fff;
  }
  .headimg .img p{
    font-size: 16px;
    padding-left: 20px;
  }
  .btn_recharge{
    background: #1E2B5F;

  }
  .btn_recharge img{
    height: 15px;
    margin-right: 5px;
  }
  .tips{
    width: 100%;
    overflow: hidden;
    padding: 0 15px;
    border-radius: 10px;
    position: relative;
    margin-top: 20px;
  }
  .wallet{
    width: 100%;
    height: 180px;
    overflow: hidden;
    padding: 0 15px;
    border-radius: 10px;
    position: relative;
    margin-top: 20px;
  }
  .wallet .bg {
    width: 100%;
    height: 100%;
  }
  .wallet .bg img{
    width: 100%;
    height: 100%;
    display: block;
  }
  .wallet .wallet_info{
    width: 100%;
    height: 100%;
    padding: 25px 30px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .wallet .wallet_info h3{
    font-size: 18px;
    text-align: left;
    font-weight: normal;
  }
  .wallet .wallet_info ul{
    padding: 20px 0;
  }
  .wallet .wallet_info ul li{
    width: 30%;
    text-align: left;
    border-right: 1px solid #fff;
  }
  .wallet .wallet_info ul li b{
    font-size: 20px;
    padding-top: 5px;
    display: block;
  }
  .wallet .wallet_info ul li:nth-child(2){
    width: 40%;
    text-align: center;
  }
  .wallet .wallet_info ul li:nth-child(3){
    text-align: center;
  }
  .wallet .wallet_info ul li:last-child{
    border: none;
  }
  .wallet .wallet_info .warning{
    justify-content: flex-start;
  }
  .wallet .wallet_info .warning img{
    height: 15px;
  }
  .wallet .wallet_info .warning p{
    font-size: 12px;
    padding-left: 10px;
    text-align: left;
  }
}
@media screen and (min-width: 640px){
  .logout{
    background: #1e2b5f;
    width: calc(100% - 30px);
    height: 55px;
    margin: 10px auto 40px;
    font-size: 16px;
  }
  .btn_login{
    background: #27DB9E;
  }
  .member_nav{
    width: calc(100% - 30px);
    margin: 30px auto;
    background: #1E2B5F;
    border-radius: 10px;
    padding: 20px 20px 0px 20px;
  }
  .member_nav h2{
    text-align: left;
    font-size: 18px;
  }
  .member_nav ul{
    padding-top: 10px;
  }
  .member_nav ul li{
    padding: 20px 0;
    border-bottom: 1px solid #eee;
    cursor: pointer;
  }
  .member_nav ul li:last-child{
    border: none;
  }
  .member_nav ul li .left img{
    height: 35px;
  }
  .member_nav ul li .left p{
    padding-left: 10px;
  }
  .member_nav ul li > img{
    height: 15px;
  }
  .content{
    width: 100%;
    margin: 0 auto;
    padding: 0 0 60px;
    height: auto;
  }
  .headimg{
    padding: 15px;
    background: #1E2B5F;
  }
  .headimg .img img{
    width: 40px;
    height: 40px;
    border-radius: 80px;
    overflow: hidden;
    border: 3px solid #fff;
  }
  .headimg .img p{
    font-size: 16px;
    padding-left: 20px;
  }
  .btn_recharge{
    background: #3EE4B2;
  }
  .btn_recharge img{
    height: 15px;
    margin-right: 5px;
  }
  .wallet{
    width: 100%;
    height: 230px;
    overflow: hidden;
    padding: 0 15px;
    border-radius: 10px;
    position: relative;
    margin-top: 20px;
  }
  .wallet .bg {
    width: 100%;
    height: 100%;
    display: none;
  }
  .wallet .bg img{
    width: 100%;
    height: 100%;
    display: block;
  }
  .wallet .wallet_info{
    width: 100%;
    height: 100%;
    padding: 25px 30px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .wallet .wallet_info h3{
    font-size: 18px;
    text-align: left;
    font-weight: normal;
  }
  .wallet .wallet_info ul{
    padding: 20px 0;
  }
  .wallet .wallet_info ul li{
    width: 30%;
    text-align: center;
    background: #298DE0;
    border-radius: 10px;
    padding: 30px 0;
  }
  .wallet .wallet_info ul li b{
    font-size: 20px;
    padding-top: 5px;
    display: block;
  }
  .wallet .wallet_info ul li:nth-child(2){
    background: #E577AE;
  }
  .wallet .wallet_info ul li:nth-child(3){
    background: #3EE4B2;
  }
  .wallet .wallet_info ul li:last-child{
    border: none;
  }
  .wallet .wallet_info .warning{
    justify-content: flex-start;
  }
  .wallet .wallet_info .warning img{
    height: 15px;
  }
  .wallet .wallet_info .warning p{
    font-size: 13px;
    padding-left: 5px;
  }
}
</style>
